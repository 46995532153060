<ion-content>
  <ion-card id="container">
    <ion-card-header>
      <ion-img src="./../../assets/logo.png"></ion-img>
    </ion-card-header>
    <ion-card-content>
      <form [formGroup]="ionicForm">
        <ion-item>
          <!-- <ion-label position="floating">Username</ion-label> -->
          <ion-input label="Username" labelPlacement="floating" formControlName="username"
            placeholder="Enter text"></ion-input>
        </ion-item>
        <ion-item>
          <!-- <ion-label position="floating">Password</ion-label> -->
          <ion-input label="Password" labelPlacement="floating" formControlName="password" type="password"
            placeholder="Password"></ion-input>
        </ion-item>
        <ion-button type="submit" expand="block" (click)="performLogin()">Log in</ion-button>
      </form>
    </ion-card-content>
  </ion-card>
  <div *ngIf="this.authService.authState == this.authService.authStates.Initializing" class="spinner-overlay">
    <ion-spinner class="spinner" name="crescent" color="primary"></ion-spinner>
  </div>
</ion-content>