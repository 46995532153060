import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './shared/services/auth.service';
import { eJudge_BASE_URL } from './shared/client/client';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';
import { LoginComponent } from './login/login.component';
import { AppConfig } from './shared/models/AppConfig';
import { AppConfigService } from './shared/services/appConfig.service';
import { ResponseInterceptor } from './shared/interceptors/response-interceptor';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { NgPipesModule } from 'ngx-pipes';
import { GlobalsService } from './shared/services/globals.service';

const appInitializerFn = (appConfigService: AppConfigService) => () => Promise.all([appConfigService.loadAppConfig()]);

const getBackendUrl = () => AppConfig.backendUrl;

@NgModule({ declarations: [AppComponent, LoginComponent, ErrorModalComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        ReactiveFormsModule,
        NgPipesModule], providers: [
        AppConfigService,
        GlobalsService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            deps: [AppConfigService],
            multi: true,
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        AuthService,
        {
            provide: eJudge_BASE_URL,
            useFactory: getBackendUrl,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
