import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { lastValueFrom, Observable } from 'rxjs';
import { AuthenticationState } from 'src/app/app.component';
import {
  ApiException,
  eJudgeClient,
  LoginRequest,
  UserDto,
} from '../client/client';

@Injectable({
  providedIn: 'any',
})
export class AuthService {
  public authStates = AuthenticationState;
  public authState = AuthenticationState.LoggedOut;
  public user: UserDto | null = null;
  constructor(
    private client: eJudgeClient,
    private router: Router,
    private alertController: AlertController
  ) { }

  public tryLogin(username: string, password: string): Observable<UserDto> {
    this.authState = this.authStates.Initializing;
    return this.client.user_Login(
      new LoginRequest({ username, password })
    );
  }

  public async getUser(): Promise<void> {
    this.authState = AuthenticationState.Initializing;
    let user = await lastValueFrom(this.client.user_CheckAuth()).catch(async (error: ApiException) => {
      this.authState = AuthenticationState.Unauthorized;
      if (error.status === 401 && this.router.url !== '/') {
        this.authState = AuthenticationState.Unauthorized;
        const alert = await this.alertController.create({
          header: 'Session Expired',
          message: 'You have been logged out. Please log in again',
          buttons: ['OK'],
          cssClass: 'unauthorized-alert',
        });
        await alert.present();
        this.router.navigate(['']);
        return true;
      } else if (error.status === 0) {
        this.authState = AuthenticationState.ConnectionFailed;
      }
    });

    if (user) {
      if (this.router.url == '/') {
        this.router.navigate(['judge-ui']);
      }
      this.user = user as UserDto;
      this.authState = AuthenticationState.LoggedIn;
    }
  }

  public async getUserDto(): Promise<UserDto | null> {
    return await this.client.user_CheckAuth().toPromise();
  }
}
