import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
  },
  // {
  //   path: 'insert',
  //   component: UploadTrampetElementComponent,
  // },
  // {
  //   path: 'createPreValue',
  //   component: CreateRequiredPrevalueComponent,
  // },
  {
    path: 'judge-ui',
    loadChildren: () =>
      import('./judge-ui/judge-ui.module').then((m) => m.JudgeUIPageModule),
  },
  // {
  //   path: 'admin-panel',
  //   loadChildren: () =>
  //     import('./admin-panel/admin-panel.module').then(
  //       (m) => m.AdminPanelPageModule
  //     ),
  // },
  // {
  //   path: 'upcoming-performances',
  //   loadChildren: () =>
  //     import('./upcoming-performances/upcoming-performances.module').then(
  //       (m) => m.UpcomingPerformancesPageModule
  //     ),
  // },
  // {
  //   path: 'upcoming-performances',
  //   loadChildren: () =>
  //     import('./upcoming-performances/upcoming-performances.module').then(
  //       (m) => m.UpcomingPerformancesPageModule
  //     ),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
