import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {

  public errorCode: number;
  constructor(private navParams: NavParams) { }

  ngOnInit() {
    this.errorCode = this.navParams.get('errorCode');
  }

}
