import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component';
import { AppConfig } from '../models/AppConfig';
import { HttpInterceptor } from './http-interceptor';
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    private modal: HTMLIonModalElement;
    constructor(private modalCtrl: ModalController, private http: HttpClient) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error) => {
                if (!(error.error instanceof ErrorEvent) && sessionStorage.getItem('currentError') !== '0'
                    && error.url.includes(AppConfig.backendUrl)) {
                    if (error.status != 401 && error.status != 400 && error.status != 404) { // eslint-disable-line eqeqeq
                        this.handleError(error.status);
                    }
                }
                return throwError(error);
            }),
        );
    }

    private async handleError(errorCode: number) {
        if (errorCode === 0) {
            sessionStorage.setItem('currentError', errorCode.toString());
            setInterval(() => {
                this.http.get(AppConfig.backendUrl).subscribe((response) => {
                    sessionStorage.setItem('currentError', null);
                    location.reload();
                },
                    (error: HttpErrorResponse) => {
                        if (error.status !== 0) {
                            sessionStorage.setItem('currentError', null);
                            location.reload();
                        }
                    });
            }, 5000);
        }
        this.modal = await this.modalCtrl.create({
            component: ErrorModalComponent,
            id: 'http-error-modal',
            componentProps: {
                errorCode: errorCode // eslint-disable-line object-shorthand
            },
            canDismiss: false
        });
        await this.modal.present();
    }
}
