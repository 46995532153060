<ion-header>
  <ion-toolbar>
    <ion-title>Error Code {{this.errorCode}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="connection-lost-wrapper" *ngIf="this.errorCode == 0">
    <h1>Connection Lost</h1>
    <div class="connection-lost-content">
      <ion-spinner name="crescent" color="primary"></ion-spinner>
      <h4>You lost connection to the Server. We are trying to reconnect...</h4>
    </div>
  </div>
  <div class="connection-lost-wrapper" *ngIf="this.errorCode == 500">
    <h1>Connection Lost</h1>
    <div class="connection-lost-content">
      <h4>A critical Server Error occured</h4>
    </div>
  </div>
</ion-content>
