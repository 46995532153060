import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ApiException } from '../shared/client/client';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public ionicForm: FormGroup;
  constructor(
    public authService: AuthService,
    private alertController: AlertController
  ) {
    this.ionicForm = new FormGroup({
      username: new FormControl(),
      password: new FormControl(),
    });
  }

  public performLogin() {
    const username = this.ionicForm.value.username;
    const password = this.ionicForm.value.password;
    this.ionicForm.reset();
    this.authService.tryLogin(username, password).subscribe({
      next: (response) => {
        this.authService.authState = this.authService.authStates.LoggedIn;
        this.authService.user = response;
        this.authService.getUser();
      },
      error: async (error: ApiException) => {
        this.authService.authState = this.authService.authStates.LoggedOut;
        if (error.status === 400) {
          this.authService.authState = this.authService.authStates.Unauthorized;
          const alert = await this.alertController.create({
            header: 'Wrong credentials',
            message: 'The given username and/or password are wrong',
            buttons: ['OK'],
            cssClass: 'unauthorized-alert',
          });
          await alert.present();
        }
      },
    });
  }
}
